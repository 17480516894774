.place-card-container {
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.place-card-image {
  width: 70%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  overflow: hidden;
}
.place-card-price {
  display: flex;
}

.skeleton-loading {
  background-color: #b4b4b4;
  animation: skeleton-loading-animation 1.5s infinite ease-in-out;
}

@keyframes skeleton-loading-animation {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.place-card-container1-skeleton-loading {
  width: 100%;
  border-radius: 8px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f0;
}

.skeleton-loading-line {
  width: 80%;
  height: 10px;
  margin-bottom: 8px;
  background-color: #b4b4b4;
  animation: skeleton-loading-flashing 1.5s infinite ease-in-out;
}

@keyframes skeleton-loading-flashing {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.place-card-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
}
.place-card-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.place-card-text1 {
  font-size: 12px;
  max-width: 250px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
@media(max-width: 767px) {
  .place-card-container {
    width: 200px;
  }
}
@media(max-width: 479px) {
  .place-card-container {
    width: 300px;
  }
}
