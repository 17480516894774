.carousel-container {
    position: relative;
    width: 100%;
    height: 40vh;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .carousel {
    display: flex;
  }

 .live-stream-item {
  background-position: center center;
  min-width: 190px;
  width: 190px;
  min-height: 264px;
  height: 264px;
  border-radius: 10px;
  box-shadow: gray 1px 1px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 36px;
  margin-left: 0px;
  margin-bottom: 16px;
  color: rgb(255, 255, 255);
  text-shadow: rgb(0, 0, 0) 2px 2px 10px;
  cursor: pointer;
  background-size: cover;
  background-color: rgb(0, 0, 0);
}

.item-info-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 12px;
  position: inherit;
  z-index: 1;
}

.time-container {
  background-color: rgb(255, 255, 255);
  border-radius: 40px;
  padding: 4px 6px;
  text-shadow: none;
  color: rgb(0, 0, 0);
}