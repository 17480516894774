.styled-button {
  display: inline-block;
  padding: 5% 10%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #39006e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s;
  text-align: center;
}

.styled-button:hover {
  background-color: #5a00ad;
}

.logo-container {
  width: 80%;
  height: 20%;
}

.logo-svg {
  width: 100%;
  height: 100%;
}